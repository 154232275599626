import { Card, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
    pageHeader: {
        display: 'flex',
        justifyContent: "center",
        marginBottom: theme.spacing(4),
        padding: theme.spacing(2),
        backgroundColor: '#e8e8e8'
    },
    pageIcon: {
        display: 'flex',
        padding: theme.spacing(2)
    },
    pageTitle: {
        alignItems: 'center',
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(2)
    }
}))

export default function PageHeader(props) {
    const { icon, title, subtitle } = props
    const classes = useStyles()
    return (
        <Paper elevation={0} square>
            <div className={classes.pageHeader}>
                <Card className={classes.pageIcon}>
                    {icon}
                </Card>

                <div className={classes.pageTitle}>
                    <Typography variant="h6" color="initial">
                        {title}
                    </Typography>
                    <Typography variant="subtitle1" color="initial">
                        {subtitle}
                    </Typography>
                </div>
            </div>
        </Paper>
    )
}
