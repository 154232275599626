import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import MessageIcon from "@mui/icons-material/Message";
import { makeStyles } from "@mui/styles";

export default function Remark(props) {
  const { remarks } = props;
  return (
    <>
      {remarks.map((remark) => {
        return <RemarkList remark={remark} key={remark.id} />;
      })}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  myList: {
    bgcolor: "background.paper",
    width: "100%",
    borderRadius: "20px",
    "&:hover": { background: "#f0ffff" },
  },
}));

function RemarkList(props) {
  const classes = useStyles();
  const { remark } = props;
  var date = new Date(remark.createdAt).toLocaleString("en-IN");
  return (
    <List className={classes.myList}>
      <ListItem>
        <ListItemAvatar>
          <MessageIcon />
        </ListItemAvatar>
        <ListItemText
          primary={remark.remark}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                remark by : {remark.createdBy}
              </Typography>
              , at : {date}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </List>
  );
}
