import React from 'react'
import MateriaTable from '@material-table/core'


export default function useTable(records, headCells) {

    const MTable = (props) => {
        const { ...other } = props
        return (<MateriaTable
            columns={headCells} data={records}
            {...other}
        />)
    }
    return { MTable }
}
