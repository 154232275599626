import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Button, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import Person from "@mui/icons-material/Person";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import Popup from "../common/controls/Popup";
import LoginForm from "../forms/LoginForm";
import { useAuth } from "../../services/Auth";
import logo from "../../images/logo.png";

const useStyles = makeStyles({
  myLinks: {
    color: "inherit",
    textDecoration: "none",
  },
  myImg: {
    borderRadius: "160px",
    width: "20%",
    margin: "10px",
  },
  myButton: {
    "&.MuiButton-root": {
      margin: "auto 0",
    },
  },
});

export default function MainHeader() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const { authToken, setAuthToken, setCurrentUser } = useAuth();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  let navigate = useNavigate();
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleLogout = () => {
    setAuthToken(false);
    setCurrentUser(null);
    //localStorage.removeItem('token')
    localStorage.clear();
    navigate("/", { replace: true });
  };
  const classes = useStyles();
  return (
    <div>
      <AppBar
        position="static"
        color="primary"
        sx={{ transform: "translateZ(0)" }}
      >
        <Toolbar>
          <Grid container>
            <Grid
              item
              xs={8}
              alignItems="center"
              sx={{ display: { xs: "flex", md: "none" } }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={handleOpenNavMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem key="search" onClick={handleCloseNavMenu}>
                  {authToken ? (
                    <Typography
                      variant="subtitle1"
                      component={Link}
                      to="/adminsearch"
                      className={classes.myLinks}
                    >
                      Search Complaint
                    </Typography>
                  ) : (
                    <Typography
                      variant="subtitle1"
                      component={Link}
                      to="/search"
                      className={classes.myLinks}
                    >
                      Search Complaint
                    </Typography>
                  )}
                </MenuItem>
                <MenuItem key="register" onClick={handleCloseNavMenu}>
                  <Typography
                    variant="subtitle1"
                    component={Link}
                    to="/"
                    className={classes.myLinks}
                  >
                    Register Complaint
                  </Typography>
                </MenuItem>
              </Menu>
              <Typography
                variant="subtitle1"
                component={Link}
                to="/"
                className={classes.myLinks}
              >
                WCR Complaint Management
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              sx={{ display: { xs: "none", md: "flex" } }}
              alignItems="center"
            >
              <img src={logo} alt="logo" className={classes.myImg} />
              <Typography
                variant="h6"
                component={Link}
                to="/"
                className={classes.myLinks}
              >
                WCR Complaint Management
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              justifyContent="space-evenly"
              sx={{ display: { xs: "none", md: "flex" } }}
              alignItems="center"
            >
              {authToken ? (
                <Typography
                  variant="subtitle1"
                  component={Link}
                  to="/adminsearch"
                  className={classes.myLinks}
                >
                  Search Complaint
                </Typography>
              ) : (
                <Typography
                  variant="subtitle1"
                  component={Link}
                  to="/search"
                  className={classes.myLinks}
                >
                  Search Complaint
                </Typography>
              )}
              <Typography
                variant="subtitle1"
                component={Link}
                to="/"
                className={classes.myLinks}
              >
                Register New Complaint
              </Typography>
            </Grid>
            <Grid item xs={4} md={3} justifyContent="flex-end" display="flex">
              {!authToken ? (
                <Button
                  color="success"
                  variant="contained"
                  startIcon={<Person />}
                  className={classes.myButton}
                  onClick={() => setOpenPopup(true)}
                >
                  Login
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.myButton}
                  onClick={handleLogout}
                >
                  logout
                </Button>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Popup
        title="User Login"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <LoginForm openPopup={openPopup} setOpenPopup={setOpenPopup} />
      </Popup>
    </div>
  );
}
