import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../services/Auth";
import LoginForm from "../forms/LoginForm";
import Popup from "./controls/Popup";

export default function ProtectedRoute() {
  const { authToken } = useAuth();
  const [openPopup, setOpenPopup] = React.useState(true);
  //const location = useLocation();
  return authToken ? (
    <Outlet />
  ) : (
    <Popup title="User Login" openPopup={true} setOpenPopup={setOpenPopup}>
      <LoginForm openPopup={openPopup} setOpenPopup={setOpenPopup} />
    </Popup>
  );
}
