import { Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import InputField from "../common/controls/InputField";
import { Form, useForm } from "../common/useForm";
import { axiosUtil } from "../../services/axiosinstance";
import { useAuth } from "../../services/Auth";
import handleError from "../../services/handleError";
import { triggerAlert } from "../../services/getAlert/getAlert";
import { useNavigate } from "react-router-dom";

const defaultValues = {
  mobileNumber: "",
  password: "",
};

const useStyles = makeStyles((theme) => ({
  myButton: {
    "&.MuiButton-root": {
      width: "90%",
      margin: theme.spacing(1.5),
    },
  },
}));

export default function LoginForm(props) {
  const { authToken, setAuthToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const { openPopup, setOpenPopup } = props;
  let navigate = useNavigate();
  const classes = useStyles();
  const validate = () => {
    let temp = {};
    temp.mobileNumber = /^\d{10}$/.test(values.mobileNumber)
      ? ""
      : "Please enter valid number";
    temp.password = values.password ? "" : "Please Field is Required";

    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, handleInputChange, errors, setErrors } =
    useForm(defaultValues);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      axiosUtil
        .post("/auth/login", values)
        .then((response) => ({ status: response.status, body: response.data }))
        .then((res) => {
          // console.log("res body : ", res.body.token);
          if (res.status === 200) {
            setAuthToken(res.body.token);
            setOpenPopup(false);
            triggerAlert({ icon: "success", title: "Login Success" });
            window.localStorage.setItem("token", res.body.token);
            navigate("/adminsearch");
          } else {
            throw res;
          }
        })
        .catch((err) => handleError(err, triggerAlert))
        .finally(() => setLoading(false));
    } else {
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid
        container
        justifyContent="center"
        display="flex"
        alignItems="center"
      >
        <Grid item xs={12} md={8}>
          <InputField
            id="login"
            name="mobileNumber"
            label="Mobile Number"
            value={values.mobileNumber}
            onChange={handleInputChange}
            autoFocus
            tab="0"
            error={errors.mobileNumber}
          />
          <InputField
            id="password"
            name="password"
            label="Password"
            type="password"
            value={values.password}
            onChange={handleInputChange}
            tab="1"
            error={errors.password}
          />
          <Grid item xs={12}>
            <Button
              variant="contained"
              type="submit"
              tabIndex={2}
              className={classes.myButton}
            >
              Login
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}
