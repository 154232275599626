import React from 'react';
import PageHeader from '../header/PageHeader';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ComplaintForm from '../forms/ComplaintForm';
import { Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import bgImage from '../../images/cms.png';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    padding: theme.spacing(1),
    margin: theme.spacing(3),
    minHeight: '50vh',
  },
  myImg: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
  },
}));

const RegisterComplaint = () => {
  const classes = useStyles();

  return (
    <>
      <PageHeader
        title="Register Complaint"
        icon={<HistoryEduIcon fontSize="large" color="secondary" />}
        subtitle="Add New Complaint"
      />
      <Grid container>
        <Grid item md={6}>
          <Paper
            className={classes.pageContent}
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            <img
              src={bgImage}
              alt="Complaint Management"
              className={classes.myImg}
            />
          </Paper>
        </Grid>
        <Grid item md={6}>
          <Paper className={classes.pageContent}>
            <ComplaintForm />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default RegisterComplaint;
