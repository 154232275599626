import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { axiosUtil } from "../../../services/axiosinstance";
import ComplaintForm from "../../forms/ComplaintForm";
import Popup from "./Popup";

export default function ComplaintCard(props) {
  const {
    complaintId,
    name,
    dateCreated,
    divisionName,
    departmentName,
    complaint,
    remark1 = null,
    remark2 = null,
    statusName = "Open",
    dateModified = null,
    fullComplaint,
  } = props;

  const [openPopup, setOpenPopup] = useState(false);
  const [recordToEdit, setRecordToEdit] = useState(null);

  const openInPopup = async (item) => {
    await axiosUtil
      .post(`/complaint/${item.complaintId}`, item)
      .then((response) => {
        setRecordToEdit({ ...fullComplaint, ...response.data[0] });
      });
    setOpenPopup(true);
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card sx={{ minWidth: 275, backgroundColor: "rgb(25,118,210,0.1)" }}>
        <CardActionArea
          onClick={(data) => {
            openInPopup(props);
          }}
        >
          <CardContent>
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              Complaint Id : {complaintId}
            </Typography>
            <Typography
              sx={{ fontSize: 17 }}
              backgroundColor="rgb(25,118,210,0.2)"
            >
              <Box component="span" fontWeight="bold">
                Complaint :{" "}
              </Box>
              {complaint}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              Registered By : {name.toUpperCase()}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              Division : {divisionName}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              Department : {departmentName}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              Registered On :{" "}
              {new Date(dateCreated).toLocaleDateString("en-IN")}
            </Typography>

            {remark1 && (
              <Typography sx={{ fontSize: 14 }}>Remark : {remark1}</Typography>
            )}
            {remark2 && (
              <Typography sx={{ fontSize: 14 }}>
                Remark by Admin : {remark2}
              </Typography>
            )}
            {dateModified && (
              <Typography sx={{ fontSize: 16 }}>
                Last Modified On :{" "}
                {new Date(dateModified).toLocaleString("en-IN")}
              </Typography>
            )}
          </CardContent>
        </CardActionArea>
        <Typography sx={{ fontSize: 16, textAlign: "center" }}>
          Current Status - <Button>{statusName}</Button>
        </Typography>
      </Card>
      <Popup
        title="Complaint"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <ComplaintForm
          recordToEdit={recordToEdit}
          setRecordToEdit={setRecordToEdit}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
    </Grid>
  );
}
