const handleError = (error, function1) => {
    if (error.status === 498) {
        localStorage.removeItem('token');
        function1(null);
    } else {
        // console.log("inside error, ", error.response.data.errors[0])
        function1({ icon: 'error', title: error?.response ? error?.response?.data?.errors[0]?.msg : 'Error' })
    };
};

export default handleError;