import React, { useEffect, useState } from "react";
import PageHeader from "../header/PageHeader";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import { useForm, Form } from "../common/useForm";
import InputField from "../common/controls/InputField";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ComplaintCard from "../common/controls/ComplaintCard";
import { axiosUtil } from "../../services/axiosinstance";

const defaultValues = {
  searchInput: "",
};

const useStyles = makeStyles((theme) => ({
  myDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(2),
  },
  pageContent: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(60),
      marginRight: theme.spacing(60),
    },
  },
  gridContainer: {
    paddingLeft: "40px",
    paddingRight: "40px",
  },
}));

export default function SearchComplaint() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [recordFound, setRecordFound] = useState(true);
  useEffect(() => {
    if (data.length > 0) {
      setRecordFound(true);
    } else {
      setRecordFound(false);
    }
  }, [data]);

  const validate = () => {
    let temp = {};
    temp.searchInput =
      values.searchInput.length <= 10 ? "" : "Please enter valid input";
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, handleInputChange, errors, setErrors } = useForm({
    ...defaultValues,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      if (values.searchInput.length === 10) {
        alert("entered Mobile Number");
        const body = {
          mobileNumber: values.searchInput,
          style: "findByMobile",
        };
        axiosUtil
          .post("/complaint", body)
          .then((response) => {
            //console.log(response.data)
            setData(response.data);
          })
          .catch((error) => {
            // console.log(error)
          });
      } else {
        const body = {
          complaintId: values.searchInput,
          style: "findByComplaintId",
        };
        axiosUtil
          .post("/complaint", body)
          .then((response) => {
            //console.log(response.data)
            setData(response.data);
          })
          .catch((error) => {
            //console.log(error)
          });
      }
    }
    //change default value in setValue from ref to shallow copy using ...
    setValues({ ...defaultValues });
  };
  // console.log("record found : ", recordFound)

  return (
    <>
      <PageHeader
        title="Search Complaint"
        icon={<ScreenSearchDesktopIcon fontSize="large" color="secondary" />}
        subtitle="Search Complaint by Id/Number"
      />
      <Paper className={classes.pageContent} elevation={0}>
        <Form onSubmit={handleSubmit}>
          <div className={classes.myDiv}>
            <InputField
              variant="outlined"
              id="searchInput"
              name="searchInput"
              lable="Search"
              value={values.searchInput}
              onChange={handleInputChange}
              autoFocus
              tab="1"
              placeholder="Search by Complaint Id/Mobile Number"
              error={errors.searchInput}
            />
            <Button variant="contained" color="secondary" type="submit">
              Search
            </Button>
          </div>
        </Form>
      </Paper>
      <Grid
        container
        spacing={4}
        className={classes.gridContainer}
        justifyContent="center"
        display="flex"
      >
        {recordFound ? (
          data.map((item) => (
            <ComplaintCard
              key={item.complaintId}
              complaintId={item.complaintId}
              name={item.name}
              dateCreated={item.dateCreated}
              divisionName={item.divisionName}
              departmentName={item.departmentName}
              complaint={item.complaint}
              remark1={item.remark1}
              remark2={item.remark2}
              statusName={item.statusName}
              dateModified={item.dateModified}
              fullComplaint={item}
            />
          ))
        ) : (
          <Typography sx={{ margin: "24px" }}>No Records to Show</Typography>
        )}
      </Grid>
    </>
  );
}
