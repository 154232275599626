import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { axiosUtil } from "../../../services/axiosinstance";

export default function Dropdown(props) {
  const [dropdownValue, setDropdownValue] = useState([]);

  const {
    name,
    lable,
    value,
    onChange,
    tab,
    api,
    error = null,
    ...other
  } = props;

  useEffect(() => {
    axiosUtil.get(api).then((response) => {
      setDropdownValue(response.data);
    });
  }, [api]);

  return (
    <FormControl variant="filled">
      <InputLabel>{lable}</InputLabel>
      <Select
        lable={lable}
        name={name}
        value={value}
        onChange={onChange}
        inputProps={{ tabIndex: tab }}
        {...(error && { error: true, helperText: error })}
        {...other}
        defaultValue=""
        id={value.toS}
      >
        {dropdownValue.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
