/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PageHeader from '../../header/PageHeader';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import useTable from '../../common/useTable';
import { axiosUtil } from '../../../services/axiosinstance';
import handleError from '../../../services/handleError';
import { triggerAlert } from '../../../services/getAlert/getAlert';
import EditIcon from '@mui/icons-material/Edit';
import Popup from '../../common/controls/Popup';
import ComplaintForm from '../../forms/ComplaintForm';
import { useAuth } from '../../../services/Auth';

// const useStyles = makeStyles(theme => ({
//     pageContent: {
//         paddingLeft: theme.spacing(3),
//         paddingRight: theme.spacing(3),
//         [theme.breakpoints.up('md')]: {
//             marginLeft: theme.spacing(6),
//             marginRight: theme.spacing(6)
//         },

//     },
// }))

export default function AdminSearch() {
  // const classes = useStyles()
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState({});
  const [division, setDivision] = useState({});
  const [department, setDepartment] = useState({});
  const [recordToEdit, setRecordToEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const { currentUser } = useAuth();
  useEffect(() => {
    if (currentUser) {
      axiosUtil
        .post('/complaint', { style: 'findAll', role: currentUser?.role })
        .then((res) => {
          if (res.status === 200) {
            setRecords(res.data);
            setIsLoading(false);
          } else {
            throw res;
          }
        })
        .catch((err) => handleError(err, triggerAlert));
      axiosUtil
        .get('/api/status')
        .then((res) => {
          if (res.status === 200) {
            const x = {};
            res.data.map((item) => (x[item.id] = item.name));
            setStatus(x);
          } else {
            throw res;
          }
        })
        .catch((err) => handleError(err, triggerAlert));
      axiosUtil
        .get('/api/department')
        .then((res) => {
          if (res.status === 200) {
            const x = {};
            res.data.map((item) => (x[item.id] = item.name));
            setDepartment(x);
          } else {
            throw res;
          }
        })
        .catch((err) => handleError(err, triggerAlert));
      axiosUtil
        .get('/api/division')
        .then((res) => {
          if (res.status === 200) {
            const x = {};
            res.data.map((item) => (x[item.id] = item.name));
            setDivision(x);
          } else {
            throw res;
          }
        })
        .catch((err) => handleError(err, triggerAlert));
    }
  }, [currentUser]);

  const headCell = [
    {
      field: 'complaintId',
      title: 'Complaint Id',
      filterPlaceholder: 'Filter by Id',
      sorting: true,
    },
    {
      field: 'name',
      title: 'Name',
      filterPlaceholder: 'Filter by Name',
    },
    {
      field: 'mobileNumber',
      title: 'Mobile No',
      filterPlaceholder: 'Filter by Mobile No',
    },
    {
      field: 'complaint',
      title: 'Complaint',
      filtering: false,
      cellStyle: { minWidth: '500px' },
    },
    {
      field: 'remark1',
      title: 'Remark',
      iltering: false,
    },
    {
      field: 'departmentId',
      title: 'Department',
      lookup: department,
    },
    {
      field: 'divisionId',
      title: 'Division',
      lookup: division,
      hidden: true,
    },
    {
      field: 'statusId',
      title: 'Status',
      lookup: status,
    },
    {
      field: 'dateCreated',
      title: 'Created On',
      type: 'date',
      emptyValue: () => <em>null</em>,
      hidden: true,
    },
    {
      field: 'dateModified',
      title: 'Updated On',
      type: 'date',
      emptyValue: () => <em>null</em>,
      hidden: true,
    },
  ];

  const { MTable } = useTable(records, headCell);

  function myRow(row) {
    const promise = new Promise((resolve) => {
      const index = row?.complaintId;
      const updateRecords = records.map((record) => {
        if (record?.complaintId === index) {
          record = row;
        }
        return record;
      });
      resolve(updateRecords);
    });
    return promise;
  }

  const openInPopup = async (item) => {
    await axiosUtil
      .post(`/complaint/${item.complaintId}`, item)
      .then((response) => {
        setRecordToEdit({ ...item, ...response.data[0] });
      });
    setOpenPopup(true);
  };

  useEffect(() => {
    if (recordToEdit !== null) {
      // console.log("inside admin search after update : ", recordToEdit);
      myRow(recordToEdit).then((updateRecords) => {
        setTimeout(() => {
          setRecords(updateRecords);
          //setRecordToEdit(null);
        });
      });
    }
  }, [recordToEdit]);

  return (
    <>
      <PageHeader
        title="Search Complaint"
        icon={<ScreenSearchDesktopIcon fontSize="large" color="secondary" />}
        subtitle="Search Complaint by Id/Number"
      />
      <MTable
        title="All Complaints"
        isLoading={isLoading}
        options={{
          filtering: true,
          columnsButton: true,
          searchAutoFocus: true,
          actionsColumnIndex: 8,
        }}
        actions={[
          {
            icon: () => <EditIcon />,
            tooltip: 'Click to edit',
            onClick: (e, data) => {
              openInPopup(data);
            },
          },
        ]}
      />
      <Popup
        title={'Complaint - ' + recordToEdit?.complaintId}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <ComplaintForm
          recordToEdit={recordToEdit}
          setRecordToEdit={setRecordToEdit}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
    </>
  );
}
