/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Dropdown from '../common/controls/Dropdown';
import InputField from '../common/controls/InputField';
import { Form, useForm } from '../common/useForm';
import { axiosUtil } from '../../services/axiosinstance';
import { centerAlert, triggerAlert } from '../../services/getAlert/getAlert';
import Remark from '../common/controls/Remark';
import { useAuth } from '../../services/Auth';

const useStyles = makeStyles((theme) => ({
  myButton: {
    '&.MuiButton-root': {
      width: '90%',
      margin: theme.spacing(1.5),
    },
  },
}));

const defaultValues = {
  complaintId: '',
  name: '',
  mobileNumber: '',
  divisionId: '',
  departmentId: '',
  complaint: '',
  remark1: '',
  statusId: '1',
};

export default function ComplaintForm(props) {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const { recordToEdit, setOpenPopup, setRecordToEdit } = props;
  const [isComplaintClose, setIsComplaintClose] = useState(false);
  const validate = () => {
    let temp = {};
    temp.name = values.name ? '' : 'Please enter your name';
    temp.mobileNumber = /^\d{10}$/.test(values.mobileNumber)
      ? ''
      : 'Please enter valid number';
    temp.complaint = values.complaint ? '' : 'Please enter your complaint';

    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === '');
  };

  const { values, setValues, handleInputChange, errors, setErrors } = useForm({
    ...defaultValues,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (values.complaintId === '') {
      if (validate()) {
        const req = { ...values, style: 'I' };
        axiosUtil
          .post('/complaint', req)
          .then((response) => {
            centerAlert({
              icon: 'success',
              title: 'Complaint Registered',
              text: 'Your Complaint Id is : ' + response.data[0].complaintId,
            });

            setValues(defaultValues);
          })
          .catch((error) => {
            //console.log(error);
          });
      } else {
        triggerAlert({ icon: 'error', title: 'Something went wrong' });
      }
    } else {
      if (
        recordToEdit.remark1.toString().toLowerCase().trim() ===
        values.remark1.toString().toLowerCase().trim()
      )
        delete values.remark1;

      const req = { ...values, style: 'U' };
      axiosUtil
        .post(`/complaint/${values.complaintId}/update`, req)
        .then((response) => {
          centerAlert({ icon: 'success', title: 'Complaint Updated' });
          setRecordToEdit({ ...response.data });
          setValues(defaultValues);
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => {
          setOpenPopup(false);
        });
    }
  };

  useEffect(() => {
    if (recordToEdit != null) {
      const x = values;
      Object.keys(x).forEach((key, index) => {
        x[key] = recordToEdit[key];
      });
      setValues({
        ...x,
      });
      if (recordToEdit.statusId === 3) setIsComplaintClose(true);
    }
  }, [recordToEdit]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container justifyContent="center" display="flex">
        <Grid item xs={12} md={6}>
          <InputField
            id="name"
            name="name"
            label="Name"
            value={values.name}
            onChange={
              values.complaintId !== ''
                ? () => {
                    return;
                  }
                : handleInputChange
            }
            autoFocus
            tab="1"
            error={errors.name}
            disabled={values.complaintId !== '' ? true : false}
          />

          <InputField
            tab="2"
            id="mobileNumber"
            name="mobileNumber"
            label="Mobile Number"
            value={values.mobileNumber}
            onChange={
              values.complaintId !== ''
                ? () => {
                    return;
                  }
                : handleInputChange
            }
            disabled={values.complaintId !== '' ? true : false}
            error={errors.mobileNumber}
            sx={{ display: { md: 'none' } }}
          />

          <Dropdown
            name="divisionId"
            lable="Divison"
            value={values.divisionId}
            onChange={
              values.complaintId !== ''
                ? () => {
                    return;
                  }
                : handleInputChange
            }
            disabled={values.complaintId !== '' ? true : false}
            api="/api/division"
            required
            tab="3"
          />

          <InputField
            id="complaint"
            name="complaint"
            label="Complaint"
            multiline
            minRows={4}
            value={values.complaint}
            onChange={
              values.complaintId !== ''
                ? () => {
                    return;
                  }
                : handleInputChange
            }
            disabled={values.complaintId !== '' ? true : false}
            error={errors.complaint}
            tab="5"
            sx={{ display: { xs: 'none', md: 'flex' } }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            tab="2"
            id="mobileNumber"
            name="mobileNumber"
            label="Mobile Number"
            value={values.mobileNumber}
            onChange={
              values.complaintId !== ''
                ? () => {
                    return;
                  }
                : handleInputChange
            }
            disabled={values.complaintId !== '' ? true : false}
            error={errors.mobileNumber}
            sx={{ display: { xs: 'none', md: 'flex' } }}
          />
          <Dropdown
            name="departmentId"
            lable="Department"
            value={values.departmentId}
            onChange={
              values.complaintId !== ''
                ? () => {
                    return;
                  }
                : handleInputChange
            }
            disabled={values.complaintId !== '' ? true : false}
            tab="4"
            api="/api/department"
            required
          />
          <InputField
            id="complaint"
            name="complaint"
            label="Complaint"
            multiline
            minRows={4}
            value={values.complaint}
            onChange={
              values.complaintId !== ''
                ? () => {
                    return;
                  }
                : handleInputChange
            }
            disabled={values.complaintId !== '' ? true : false}
            error={errors.complaint}
            tab="5"
            sx={{ display: { md: 'none' } }}
          />
          {values.complaintId === '' ? (
            ''
          ) : (
            <Dropdown
              name="statusId"
              lable="Current Status"
              value={values.statusId}
              onChange={handleInputChange}
              api="/api/status"
              disabled={isComplaintClose || !currentUser ? true : false}
              required
            />
          )}
          <InputField
            id="remark1"
            name="remark1"
            label="Remark"
            multiline
            value={values.remark1}
            onChange={handleInputChange}
            disabled={isComplaintClose || !currentUser ? true : false}
            tab="6"
          />
          {values.complaintId === '' ? (
            <Button
              variant="contained"
              className={classes.myButton}
              type="submit"
              tabIndex={7}
            >
              Submit
            </Button>
          ) : (
            ''
          )}
        </Grid>
        {values.complaintId !== '' ? (
          <Remark remarks={recordToEdit.remarks} />
        ) : (
          ''
        )}
        {currentUser && !isComplaintClose && values.complaintId !== '' ? (
          <Button
            variant="contained"
            className={classes.myButton}
            type="submit"
            tabIndex={7}
          >
            Update
          </Button>
        ) : (
          ''
        )}
      </Grid>
    </Form>
  );
}
