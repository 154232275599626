import Swal from "sweetalert2/dist/sweetalert2";
import "@sweetalert2/theme-dark/dark.css";
import "./styles.css";

const getAlert = () => {
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 3000,
    background: "#10116E",
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  return Toast;
};

export const triggerAlert = (data) => {
  const toast = getAlert();
  toast.fire(data);
};

export const centerAlert = (data) => {
  Swal.fire({
    icon: "",
    title: "",
    text: "",
    ...data,
  });
};

export default getAlert;
