import { makeStyles } from "@mui/styles";
import React, { useState } from "react";

export function useForm(defaultValues) {
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      margin: theme.spacing(1.5),
      width: "90%",
      [theme.breakpoints.down("md")]: {
        width: "90%",
      },
    },
  },
}));

export function Form(props) {
  const classes = useStyles();
  const { ...other } = props;
  return (
    <form autoComplete="off" className={classes.root} {...other}>
      {props.children}
    </form>
  );
}
