import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import MainHeader from "./components/header/MainHeader";
import RegisterComplaint from "./components/pages/RegisterComplaint";
import SearchComplaint from "./components/pages/SearchComplaint";
import { AuthContext } from "./services/Auth";
import jwt_decode from "jwt-decode";
import ProtectedRoute from "./components/common/ProtectedRoute";
import AdminSearch from "./components/pages/admin/AdminSearch";

const theme = createTheme();

function App() {
  const [authToken, setAuthToken] = useState(
    window.localStorage.getItem("token")
  );
  const [currentUser, setCurrentUser] = useState();

  // load token from localstorage
  useEffect(() => {
    const token = window.localStorage.getItem("token");
    if (token) {
      const user = window.localStorage.getItem("user");
      setAuthToken(token);
      setCurrentUser(user);
    }
  }, []);

  // load token from localstorage
  window.onstorage = () => {
    const token = window.localStorage.getItem("token");
    const user = window.localStorage.getItem("user");
    setAuthToken(token);
    setCurrentUser(user);
  };

  useEffect(() => {
    if (authToken) {
      var decoded = jwt_decode(authToken);
      window.localStorage.setItem("user", JSON.stringify(decoded));
      setCurrentUser(decoded);
    } else {
      setCurrentUser(null);
    }
  }, [authToken]);

  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider
        value={{ authToken, setAuthToken, currentUser, setCurrentUser }}
      >
        <MainHeader />
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path="/adminsearch" element={<AdminSearch />} />
          </Route>
          <Route path="/*" element={<RegisterComplaint />} />
          <Route path="/search" element={<SearchComplaint />} />
        </Routes>
        <CssBaseline />
      </AuthContext.Provider>
    </ThemeProvider>
  );
}

export default App;
